import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Home1, Home2, Gallerya, Galleryb, Galleryc, Sheila } from '../../assets';
import { Link } from "react-router-dom";

function Home() {
    return (
        <>
            <section className='main-1'>
                <Container>
                    <Row className='align-items-center'>
                        <Col sm={6}>
                            <h3 className="main1-a">Explore The Remarkable Journey Of USA’s 45th President!</h3>
                            <p className="main1-b">Art Revelations presents an exclusive collection of NFTs focused on specific, noteworthy, decisive actions of former President Donald J Trump.   </p>
                            <a href='#' className='explore-btn'>Let’s Get Started!</a>
                        </Col>
                        <Col sm={6} className='text-end'>
                            <img src={Home1} className='Home1-img' />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='main-2'>
                <Container>
                    <Row className='align-items-center'>
                        <Col sm={7}>
                            <img src={Home2} className='Home2-img' />
                        </Col>
                        <Col sm={5}>
                            <h3 className="main2-a">Meet The Artist - Dr. Sheila Adams</h3>
                            <p className="main2-b">Dr. Sheila Adams, an Artist and Psychologist, has joined us to digitally depict some of the of the recent true events, in ways that insightfully highlight understanding of both the well-intended, extraordinary goals of Donald Trump and the possible restraining factors, that can be imposed by psychological defenses that compete with those goals.
                                <br /><br />
                                At Art Revelations, you will embark on a journey into the fusion of artistry and psychology. Our featured artist and psychologist has crafted each NFT, offering an insightful, fascinating exploration of Donald Trump’s behavior, actions, statements and the presumable multifaceted impact.
                            </p>
                            <Link to='./Aboutus' className='gallery-btn'>Explore More About The Artist!</Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='main-3'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h4 className="main3-a">OUR VISION</h4>
                            <p className="main3-b">We envision becoming the definitive archive of Donald J Trump’s indelible actions, empowering his fans and motivating even non-fans, to be NFT Collectors, and own, trade and acknowledge Trump’s iconic actions, captured by our popular collectible NFTs. Our goal is to bridge the gap between conventional momentos and state-of-the-art blockchain technology. These conceptually captured, iconic moments, will be enviably enjoyed in your own NFT Collection.</p>
                            <a href='#' className='read-btn'>Own Your NFT Now! </a>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='main-4'>
                <Container>
                    <Row className='align-items-center'>
                        <Col sm={7}>
                            <h3 className="main4-a">Explore Our NFT Gallery</h3>
                            <p className="main4-b">The NFT gallery at Art Revelations is more than just a digital collection. Step through the virtual doors and have a chance to own a piece of the Trump phenomenon. Get your own piece of cultural and political movement that is continuously shaping the world. </p>
                            <p className="main4-b">Browse and select from these Trump NFT digital art works! </p>

                            <div className="gallery-div">
                                <Row>
                                    <Col sm={6}>
                                        <img src={Gallerya} className='gallerya' />
                                    </Col>
                                    <Col sm={6}>
                                        <img src={Galleryb} className='gallerya' />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col sm={5}>
                            <img src={Galleryc} className='gallerymain-img' />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='main-5'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 className="main5-a">ROAD MAP</h3>
                            <p className="main5-b">“Dive into the fusion of art and psychology, that depicts paradoxical behaviors of Donald Trump”.</p>
                        </Col>
                        <Col sm={12}>
                            <div className="roadmap-div">
                                <Row className='align-items-end'>
                                    <Col sm={3}>
                                        <div className="roadmap-inner">
                                            <h4 className="main5-c">Explore the digital tapestry </h4>
                                            <p className="main5-d">Immerse yourself in our curated collection, experiencing psychological perspectives in artfully captured pivotal moments of Donald J Trump’s important positive and some questionable moments that have had -and will likely continue to have, a profound impact on this era. </p>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="roadmap-inner1">
                                            <h4 className="main5-c">Be part of exclusive community.</h4>
                                            <p className="main5-d">Connect with like-minded enthusiasts and some contrary perspectives within the NFT Collector’s Community. Our Artist and Psychologist respects that “We each have unique pieces of the puzzle, to share for the benefit of expanding and deepening our insight”.  Participate in the controversial discussions of the exclusive events we have curated.</p>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="roadmap-inner2">
                                            <h4 className="main5-c">Secure your own collection of NFT’s representing controversial perspectives of Trump’s actions.</h4>
                                            <p className="main5-d">Acquire your preferred NFTs securely, utilizing blockchain technology to ensure authenticity, transparency, and traceability, while engaging in the dynamic marketplace to trade</p>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="roadmap-inner3">
                                            <h4 className="main5-c">Elevate The NFT Experience</h4>
                                            <p className="main5-d">We are setting new standards by insightfully expanding our grasp of historical narratives to include additional, alternative psychological vantage points, for your enlightenment and insight development, as you enjoy and share your NFT Collection! Congratulations!!</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='main-6'>
                <Container>
                    <Row>
                        <Col sm={6} className='px-4'>
                            <h4 className="main6-a">CONTACT US</h4>
                            <form>
                                <input type="text" name="name" placeholder='Your Name' className='name-input' required />
                                <input type="email" name="name" placeholder='Your Email' className='name-input' required />
                                <textarea rows="8" placeholder='Your Message' className='msg-input' required></textarea>
                                <input type="submit" className='submit-btn' value="Submit" />
                            </form>
                        </Col>
                        <Col sm={6} className='px-4'>
                            <img src={Sheila} className='sheila-img' />
                            <h4 className="main6-b">About Sheila Adams</h4>
                            <p className="main6-c">Dr Sheila Adams is an Artist and a Psychologist.
                                Her paintings, sculpture and photography all showcase a thought provoking fusion of artistic vision and psychological insight.
                                She holds both a Doctorate Degree and Masters Degree from Harvard University.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Home