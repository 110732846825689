import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header/Header';
import Home from './components/Pages/Home';
import Footer from './components/Footer/Footer';
import Aboutus from './components/Pages/Aboutus';
import Roadmap from './components/Pages/Roadmap';
import Collection from './components/Pages/Collection';
import SingleProduct from './components/Pages/SingleProduct';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Aboutus' element={<Aboutus />} />
          <Route path='/Roadmap' element={<Roadmap />} />
          <Route path='/Collection' element={<Collection />} />
          <Route path='/SingleProduct/:id' element={<SingleProduct />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;