import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Footerlogo, Footertop } from '../../assets';
import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <footer>
                <Container>
                    <Row className='align-items-end'>
                        <Col sm={12}>
                            <img src={Footertop} className='footertop-img' />
                        </Col>
                        <Col sm={6}>
                            <a href='#'><img src={Footerlogo} className='footer-logo' /></a>
                        </Col>
                        <Col sm={6} className='text-end'>
                            <ul className='footer-ul'>
                                <Link to='/'><li>Home</li></Link>
                                <Link to='./Aboutus'><li>ABOUT US</li></Link>
                                <Link to='./Roadmap'><li>ROADMAP</li></Link>
                                <Link to='./Collection'><li>COLLECTION</li></Link>
                                <Link to='#'><li>MINT</li></Link>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <Container className='footer-container'>
                    <Row>
                        <Col sm={6}>
                            <p className="footer-a">Design Engineered by Digital Nest Studio © 2024</p>
                        </Col>
                        <Col sm={6}>
                            <div className="copyright-div">
                                <a href='#' className='footer-b'>Privacy & Policy</a>
                                <a href='#' className='footer-b'>Terms Condition</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer