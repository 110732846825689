import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Logo, Whitepaper } from '../../assets/index';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

function Header() {
    return (
        <Navbar expand="lg" className="navbar-dark">
            <Container>
                <Navbar.Brand to="/"><img src={Logo} className='Logo' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/">Home</Link>
                        <Link to="./Aboutus">ABOUT Us</Link>
                        <Link to="Roadmap">Roadmap</Link>
                        <Link to="Collection">Collection</Link>
                        <Link to={Whitepaper} target="_blank" download>Whitepaper</Link>
                        <Link to="#">Mint</Link>
                    </Nav>
                    <Button href="#" className='connect-btn'>Connect Wallet</Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header