import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import products from '../Products';

function SingleProduct() {
    const { id } = useParams();
    const product = products.find(product => product.id === id);
    
    console.log(id)

    function productRemover(){
        products.splice(8,1)
    }

    // Check if product is defined before destructure
    if (!product) {
        return <div>Product not found</div>;
    }

    const { name, short_des, description, image } = product;

    return (
        <section className='nft-inner'>
            <Container>
                <Row>
                    <Col sm={6}>
                        <img src={image} alt={name} className='nftinner-img' />
                    </Col>
                    <Col sm={6}>
                        <h3 className='nftinner-h3'>{name}</h3>
                        <p className='nftinner-p'>{short_des}</p>
                        <p className='nftinner-p'>{description}</p>
                        <a href='#' className='mint-btn' onClick={productRemover}>Mint</a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SingleProduct;