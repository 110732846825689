const products = [
    {
        id: '1',
        name: 'Holy Shit',
        image: require('../assets/collection/trump1.png'),
        short_des:'“83.3 Millions?!!!”',
        description: '(None of the other pay-offs ever cost me THAT much!” This quote shows that his previous denials of sexual assault allegations and pay offs, were lies)'
    },
    {
        id: '2',
        name: 'January 6, 2021',
        image: require('../assets/collection/trump2.png'),
        short_des:'“I AM THE F-ing PRESIDENT! TAKE ME TO THE F-ING CAPITAL!”',
        description: '(The chauffeur told the January 6 Investigation Committee Chairman, Liz Cheney, that Trump moved up in the limousine and reached over to grab his throat, demanding to be taken to the Capital, where the Insurrection was under way. Secret Service had instructed the chauffeur to bring Trump back to the oval office, to protect him from the violent mob scene.)'
    },
    {
        id: '3',
        name: 'MAR-A-LAGO  POWDER  ROOM',
        image: require('../assets/collection/trump3.png'),
        short_des: '“You wouldn’t believe how accurate our warheads are!”',
        description: '(The powder room at this resort, which is open for anyone to enter and explore, -has been used as a storage room for the Confidential Documents and Top Secret Documents, Trump had illegally removed from the White House, to Mar-A-Lago, for his unknown personal reasons. He shows disregard for protecting this country’s well-being, casually telling someone about the accuracy of our warheads, potentially generating risks for our country)'
    },
    {
        id: '4',
        name: '“Mistakes Were Made But Not By Me!” ',
        image: require('../assets/collection/trump4.png'),
        short_des:'“I can’t believe I lost to that F-ing guy”',
        description: '(This was said to Mark Meadows, Trump’s Chief of Staff, who emphatically told Trump he had lost to Biden. This quote shows that Trump knew the truth, but couldn’t face being “a loser”, so he denied (and still denies knowing he lost.) Sixty court cases alleging fraudulent corruption were deemed unfounded by the courts because there was no legitimate evidence found.).'
    },
    {
        id: '5',
        name: 'Our Saviour',
        image: require('../assets/collection/trump5.png'),
        description: 'Trump quote (to staffers): “Just say it was corrupt -and leave the rest to me” The image shows Trump generating trust from his followers, to deny the election results and see him as unfairly removed from the Presidency. He reaches out to create “believers” to follow and support him. Beliefs often override verifiable truth.'
    },
    {
        id: '6',
        name: 'The Border Wall Crisis',
        image: require('../assets/collection/trump6.png'),
        short_des: '“Hey, speaker Mike! On second thought, let’s just keep letting all these Thugs and Bad, very bad people in….just until I’m back in office. -This makes Biden look pretty bad.”',
        description: '(The Speaker of the House subsequently refused to address the Biden Border Bill to address the Border wall issues in ways that stop the random influx of thousands of migrants and establish a controlled management of asylum seeking people, processing them in ways that select specific viable applicants, to enter this country and secure employment, to contribute to this country. Without allowing a vote on Biden’s Border Bill, funding for Ukraine’s defense, costs thousands more lives.) '
    },
    {
        id: '7',
        name: '"STOP THE STEAL!”',
        image: require('../assets/collection/trump7.png'),
        short_des: '“I told you to stop the steal, just find me 11,780 votes.',
        description: '(Trump asked staffers to get fake electors to (fraudulently) claim there were 11,780 votes for Trump, not included in the official, verified vote count. The staffers were arrested for conspiring to defraud the government, and pled guilty, under oath, in exchange for no jail time. For telling the truth, the staffers were freed from jail terms and stated : “I got probation”, “I got a suspended sentence”, “I got fined”. Trump essentially felt ‘thrown under the bus and betrayed by the staffers. No regard for their now having an Arrest Record for following his orders.).'
    },
    {
        id: '8',
        name: 'January 6, 2025 ',
        image: require('../assets/collection/trump8.png'),
        short_des: '“I’m going to be back in the Oval Office, standing on MY 91 indictments!”',
        description: '( The obvious next step for Trump as re-elected President is expected to be, an immediate official Presidential Pardon of himself, for all of these 91 indictments!) (The first act as President will be Pardoning himself for all felonies.)'
    },
    
]



export default products;