import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import products from '../Products';
// import AllNFTs from './AllNFTs';

function Collection() {
    return (
        <>
            <section className="collection-1">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 className="collection1-a">Explore Our NFT Gallery</h3>
                            <p className="collection1-b">The NFT gallery at Art Revelations is more than just a digital collection. Step through the virtual doors and have a chance to own a piece of the Trump phenomenon. Get your own piece of cultural and political movement that is continuously shaping the world. <br /> <br /> Browse and select from these Trump NFT digital art works!</p>
                        </Col>

                        <div className="collection-div">
                            {products.map((product) => (
                                <div key={product.id}>
                                    <Link to={`/SingleProduct/${product.id}`}>
                                        <img src={product.image} alt={product.name} style={{ width: '100%', height: 'auto' }} />
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Collection;