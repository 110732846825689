import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Home2 } from '../../assets';

function Aboutus() {
  return (
    <>
      {/* <section className="about-1">
        <Container>
          <Row>
            <Col sm={6}>
              <h3 className="about1-a">About Us</h3>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className='main-2 about-1'>
        <Container>
          <Row className='align-items-center'>
            <Col sm={7}>
              <img src={Home2} className='Home2-img' />
            </Col>
            <Col sm={5}>
              <h3 className="main2-a">Meet The Artist - Dr. Sheila Adams</h3>
              <p className="main2-b">Dr. Sheila Adams, an Artist and Psychologist, has joined us to digitally depict some of the of the recent true events, in ways that insightfully highlight understanding of both the well-intended, extraordinary goals of Donald Trump and the possible restraining factors, that can be imposed by psychological defenses that compete with those goals.
                <br /><br />
                At Art Revelations, you will embark on a journey into the fusion of artistry and psychology. Our featured artist and psychologist has crafted each NFT, offering an insightful, fascinating exploration of Donald Trump’s behavior, actions, statements and the presumable multifaceted impact.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='main-3'>
        <Container>
          <Row>
            <Col sm={12}>
              <h4 className="main3-a">OUR VISION</h4>
              <p className="main3-b">We envision becoming the definitive archive of Donald J Trump’s indelible actions, empowering his fans and motivating even non-fans, to be NFT Collectors, and own, trade and acknowledge Trump’s iconic actions, captured by our popular collectible NFTs. Our goal is to bridge the gap between conventional momentos and state-of-the-art blockchain technology. These conceptually captured, iconic moments, will be enviably enjoyed in your own NFT Collection.</p>
              <a href='#' className='read-btn'>Own Your NFT Now! </a>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Aboutus