import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Roadmap() {
    return (
        <>
            <section className='main-5'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h3 className="main5-a">ROAD MAP</h3>
                            <p className="main5-b">“Dive into the fusion of art and psychology, that depicts paradoxical behaviors of Donald Trump”.</p>
                        </Col>
                        <Col sm={12}>
                            <div className="roadmap-div">
                                <Row className='align-items-end'>
                                    <Col sm={3}>
                                        <div className="roadmap-inner">
                                            <h4 className="main5-c">Explore the digital tapestry </h4>
                                            <p className="main5-d">Immerse yourself in our curated collection, experiencing psychological perspectives in artfully captured pivotal moments of Donald J Trump’s important positive and some questionable moments that have had -and will likely continue to have, a profound impact on this era. </p>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="roadmap-inner1">
                                            <h4 className="main5-c">Be part of exclusive community.</h4>
                                            <p className="main5-d">Connect with like-minded enthusiasts and some contrary perspectives within the NFT Collector’s Community. Our Artist and Psychologist respects that “We each have unique pieces of the puzzle, to share for the benefit of expanding and deepening our insight”.  Participate in the controversial discussions of the exclusive events we have curated.</p>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="roadmap-inner2">
                                            <h4 className="main5-c">Secure your own collection of NFT’s representing controversial perspectives of Trump’s actions.</h4>
                                            <p className="main5-d">Acquire your preferred NFTs securely, utilizing blockchain technology to ensure authenticity, transparency, and traceability, while engaging in the dynamic marketplace to trade</p>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <div className="roadmap-inner3">
                                            <h4 className="main5-c">Elevate The NFT Experience</h4>
                                            <p className="main5-d">We are setting new standards by insightfully expanding our grasp of historical narratives to include additional, alternative psychological vantage points, for your enlightenment and insight development, as you enjoy and share your NFT Collection! Congratulations!!</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Roadmap